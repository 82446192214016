import React from "react";
import { Link } from "gatsby";
import theme from "../styles/theme";

const FeaturePageHeroButtons = ({ data }) => {
  return (
    <div
      css={`
        display: flex;
        flex-wrap: wrap;
        z-index: 1;
        position: relative;
        a {
          margin-top: 20px;
        }
        a:not(:last-child) {
          margin-right: 20px;
        }
      `}
    >
      {data.map(i => (
        <Link className="button pinkOutlines" to={i.path}>
          {i.text}
        </Link>
      ))}
    </div>
  );
};

export default FeaturePageHeroButtons;
