import React from "react";

const VideoBtns = ({ videos, handleWatchDemo }) => {
  return (
    <div
      css={`
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        p {
          font-size: 20px;
          line-height: 120%;
          color: #ec008c;
          font-weight: 700;
          margin-bottom: 10px;
          width: 100%;
        }
        > div {
          width: 50%;
          max-width: 260px;
          min-width: 180px;
          padding-right: 10px;
          box-sizing: border-box;
          button {
            width: 75%;
            min-width: 160px;
            &:hover {
              opacity: 0.8;
            }
          }
        }
      `}
    >
      {videos.map(item => {
        return (
          <div>
            <p>{item.title}</p>
            <button
              className="pinkFilled"
              onClick={() => handleWatchDemo(item)}
            >
              Watch demo
            </button>
          </div>
        );
      })}
    </div>
  );
};

export default VideoBtns;
