import React from "react";
import ReactDOM from "react-dom";
import "styled-components/macro";

const Modal = ({ children, onClose, maxWidth }) => {
  const [target] = React.useState(() => document.createElement("div"));
  React.useEffect(() => {
    document.body.appendChild(target);
    const rect = document.body.getBoundingClientRect();
    document.body.style["overflow-y"] = "hidden";
    //eslint-disable-next-line no-unused-expressions
    document.body.scrollTop;
    const rect2 = document.body.getBoundingClientRect();
    document.body.style["padding-right"] = `${rect2.width - rect.width}px`;
    return () => {
      document.body.removeChild(target);
      document.body.style["overflow-y"] = "";
      document.body.style["padding-right"] = "";
    };
  }, [target]);
  return ReactDOM.createPortal(
    <div
      onMouseDown={e => {
        if (e.target !== e.currentTarget) {
          return;
        }
        e.preventDefault();
        onClose();
      }}
      css={`
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        overflow: hidden;
        outline: 0;
        z-index: 100;
        display: flex;
        justify-content: center;
        align-items: center;
        background: rgba(255, 255, 255, 0.55);
        transition: all 0.4s;
      `}
    >
      <div
        className="modal"
        style={{
          width: "100%",
          maxWidth: maxWidth || 775,
          maxHeight: "100vh",
          background: "#fff",
          display: "flex",
          flexDirection: "column",
          borderRadius: "12px",
          boxShadow: "0px 0px 32px rgba(0, 0, 0, 0.125)",
          position: "relative",
          color: "#474747",
        }}
      >
        <button
          style={{
            position: "absolute",
            right: 10,
            top: 10,
            lineHeight: 1,
            outline: "none",
            padding: 5,
            background: "transparent",
            border: "none",
            cursor: "pointer",
            fontSize: 32,
            color: "#474747",
          }}
          onClick={e => {
            e.preventDefault();
            onClose();
          }}
        >
          &times;
        </button>
        <div style={{ padding: "40px 40px", overflowY: "hidden" }}>
          {children}
        </div>
      </div>
    </div>,
    target
  );
};

export default Modal;
